import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Octubre"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/octubre"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/octubre",
        title: "Efemérides - Octubre | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – OCTUBRE</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de octubre de 1779&nbsp;: Nació en Salta, Francisco Remigio Castellanos.
      En 1805 se recibió de abogado en la Unversidad de Chuquisaca. Fue Asesor
      General del Ayuntamiento de esta ciudad. Fue transferido a Montevideo con
      el mismo cargo, ya que ambas ciudades integraban el Virreinato del Río de
      la Plata. Cuando el ejérctio patriota avanzó sobre Montevideo se unió a
      las fuerzas patrioticas en las que le fue asignado el cargo de Auditor de
      Guerra. En 1813 los pueblos de la Banda Oriental convocaron a Congreso
      Constituyente, designóse una junta de gobierno integrado por 3 miembros,
      uno de los cuales era salteño Castellanos. Más adelante el Gobernador
      Intendente de Montevideo, Don Juan Durán, lo designó Asesor de la
      Gobernación, cargo que luego habría de desempeñar junto al titular de
      gobierno de Buenos Aires. Al año siguiente regresó a Montevideo para
      ocupar nuevas funciones oficiales, a las que luego habría de desempeñar
      junto al titular de gobierno de Buenos Aires. Al año siguiente regresó a
      Montevideo para ocupar nuevas funciones oficiales, a las que renunció para
      volver para volver otra vez a Bs. As. donde ocupó el cargo de Asesor del
      Cabildo hasta 1818. De nuevo en Salta en 1824 fue elegido Diputado por
      ella ante el Congreso Constituyente reunido en Bs. As. en el que brindó su
      apoyo a Rivadavia. En la disyuntiva surgida entre federales y unitarios,
      Castellanos se inclinó por los unitarios. En consecuencia, en 1831 emigró
      a Montevideo, donde Lavalle lo nombró Presidente del Superior Tribunal de
      Justicia. Falleció en Montevideo el 14 de abril de 1839.
    </p>
    <p>
      1 de octubre de 1819: Nómbrase Presidente del Congreso, al Diputado por
      Salta, Dr. Marcos Salomé Zorrila.
    </p>
    <p>
      1 de octubre de 1838: Falleció en Tucumán, Monseñor José Agustín molina.
      La Diócesis de Salta quedaba sin pastor.
    </p>
    <p>
      1 de octubre de 1908:&nbsp;Se instaló en Salta, la sucursal del Banco
      Español del Río de la Plata limitado.
    </p>
    <p>
      1 de octubre de 1950: Nació en Salta, Víctor Hugo Claros, quien estudió en
      la escuela Zorrilla, el secundario en el Colegio Salesiano y se graduó de
      contador público nacional en la Universidad Nacional de Salta en el año
      1975, en una de las primeras promociones. Desde hace más de veinte años se
      desempeña como profesor adjunto en la UNSa. Es miembro de CECYT y asesor
      en Contabilidad y Auditoría del Consejo Profesional de Ciencias
      Económicas. Dicta cursos a profesionales, conferencista y expositor en
      Congresos Nacionales. Fue gerente del Banco de Préstamos y Asistencia
      Social.
    </p>
    <p>
      1 de octubre de 1983:&nbsp;Falleció en Tucumán, Monseñor José Agustín
      Molina. La Diócesis de Salta quedaba sin pastor.
    </p>
    <p>
      1 de octubre de 1995: En elecciones populares triunfa la fórmula integrada
      por el doctor Juan Carlos Romero y Walter Wayar, para ocupar la
      gobernación y vicegobernación de la provincia de Salta.
    </p>
    <p>
      2 de octubre de 1919: Falleció en la Capital Federal, el doctor en
      Jurisprudencia Victorino de la Plaza. Nació en Salta, el 2 de noviembre de
      1840. Por fallecimiento del presidente Sáenz Peña, asumió la primera
      magistratura para entregársela a su sucesor Hipólito Yrigoyen, el 12 de
      octubre de 1916. Su biblioteca privada fue donada por testamento a la
      provincia de Salta, originando la Biblioteca Provincial Victorino de la
      Plaza.
    </p>
    <p>
      2 de octubre de 1931: Fue designado Interventor Federal de la provincia de
      Salta, don Erasmo Martínez.
    </p>
    <p>
      2 de octubre de 1995: Falleció en Salta, Joaquín Vedoya Beristayn, quien
      alcanzó a publicar un libro épico sobre la figura del General Güemes.
    </p>
    <p>
      3 de octubre de 1841: Fue ejecutado en Metán, provincia de Salta, el
      doctor Marco M. de Avellaneda, junto con el coronel José María Vilela, el
      comandante Luciano Casas, sargento mayor Gabriel Suárez, capitán José
      Espejo (hermano del General Jerónimo Espejo) ,el teniente 1º Leonardo
      Souza, por pertenecer a la Liga del Norte, que se pronunció en armas
      contra el gobierno de la Confederación. Estas ejecuciones fueron
      realizadas a raíz de la derrota que el general Manuel Oribe, infligió al
      general Juan Lavalle en la batalla de Famaillá, en la provincia de
      Tucumán, el 19 de setiembre de 1941. Los federales al mando de Oribe
      asesinaron a Marco Avellaneda, quien era jefe de la Coalición del Norte,
      en Metán, provincia de Salta.
    </p>
    <p>
      3 de octubre de 1930: Nació en la provincia de Salta, René Francisco Bum,
      quien se recibió de médico en la Universidad de Buenos Aires en 1955.
      Docente de la Universidad de Buenos Aires e investigador.
    </p>
    <p>
      4 de octubre de 1811: Llegó a Salta (ciudad) el Presidente de la Junta,
      brigadier Cornelio Saavedra con una escolta de húsares de Buenos Aires y
      soldados del Regimiento Nº 4, en compañía del vocal doctor Manuel Felipe
      Molina, quienes permanecieron hasta enero de 1812.
    </p>
    <p>
      4 de octubre de 1845: Falleció, en Sucre, Bolivia, Juan José Gregorio
      Fernández. Nació en Salta en 1790. Se incorporó a la carrera de las armas
      en las filas patrióticas. Luchó en Cotagaita, Suipacha y Huaqui. Siguió
      con Belgrano, participando del éxodo y de las batallas de Tucumán y Salta.
      Luego estuvo en Vilcapugio y Ayohuma, donde cayo prisionero de los
      realistas por ocho años. Después de Guayaquil pasó a las órdenes de
      Bolívar, quien lo ascendió al grado de general. En la Paz, en 1826, se le
      asignó el cargo de Prefecto.
    </p>
    <p>
      4 de octubre de 1931: Nació en Tartagal, provincia de Salta, Ermes Riera.
      Siguió los pasos de su padre don Juan Riera, el Juan Panadero de la zamba,
      dedicándose al mismo negocio. Se destacó como dirigente de la Cámara de
      Panaderos, que lo tuvo como pionero, siendo uno de los gestores de la
      construcción de su edificio en la calle Alvarado. Formó parte de la
      Confederación General Económica, de la Cooperativa Popular Salteña y fue
      presidente del Club Atlético Pellegrini.
    </p>
    <p>
      4 de octubre de 1959: Murió en Salta, Policarpio Romero, fundador y
      director de La Provincia. Integró diversas entidades culturales y
      comisiones honorarias. Participó en la primera “Reunión de Historia del
      Norte”.
    </p>
    <p>
      4 de octubre de 1970: Se inauguró el nuevo edificio para la policía de
      Metán. Su primer jefe fue el comisario inspector, Alberto Rallé.
    </p>
    <p>
      5 de octubre de 1831: El comandante Blanco derrotó a las tropas del
      coronel Fructuoso Ontiveros unida a la que los colombianos del coronel
      Domingo López Matute, en Canteros, provincia de Salta.
    </p>
    <p>
      5 de octubre de 1856: Asumió como Gobernador Interino del General
      Rudecindo Alvarado, Martín Güemes y Puch, hijo del General Martín Miguel
      de Güemes. Como gobernador Provisorio dio los últimos pasos necesarios
      para poner a la provincia en la senda constitucional, acelerando la
      elección de un Gobernador Propietario y Constitucional.
    </p>
    <p>
      5 de octubre de 1937: Nació en San Ramón de la Nueva Orán, provincia de
      Salta, Humberto Jesús Moyorga, quien se recibió de médico en la
      Universidad de Buenos Aires en 1964. Se perfeccionó en Illinois y N.
      Orleans, Estados Unidos. Se especializó en Oftalmología. Se desempeñó en
      distintos centros asistenciales de la Capital Federal y del Gran Buenos
      Aires. Publicó numerosísimos trabajos científicos. logró premios
      nacionales e internacionales. En Morón, Buenos Aires, fijó su residencia,
      donde es el fundador y director de la Clínica Privada de Cirugía Ocular.
      Miembro de entidades argentinas y extranjeras.
    </p>
    <p>
      5 de octubre de 1944: Se inauguró el tramo pavimentado que unía Metán con
      Rosario de la Frontera. Gobernaba la provincia el Dr. Arturo S. Fassio.
    </p>
    <p>
      6 de octubre de 1974: Se inauguró el VII Congreso Eucarístico Nacional. A
      las 17 hs. de ese día, 12 arzobispos y obispos acompañaron a la Virgen del
      Milagro, junto a 100 sacerdotes, autoridades civiles y militares y
      millares de fieles, hasta la intersección de las Avenidas Güemes y Virrey
      Toledo de la ciudad de Salta, donde se levantaba el altar. Se izó la
      Bandera Argentina y la Bandera del Vaticano, por parte del gobernador
      Miguel Ragone y el arzobipo Monseñor Pérez. El Congreso culminó el domingo
      13 de octubre con la presencia de la Presidente de la nación,doña María
      Estela Martínez de Perón, el Legado Pontificio Cardenal Silvio Oddi junto
      a 47 arzobispos y obispos y 3 prelados del extranjero.
    </p>
    <p>
      7 de octubre de 1951: Nació en Tucumán, Pedro Raúl Sánchez. Maestro y
      periodista en LRA 15 Radio Nacional Tucumán. Obtuvo varios premios
      literarios. Fundó el grupo Arte en Tafí Viejo y presidió el grupo Cultural
      también en Tafí Viejo provincia de Tucumán.
    </p>
    <p>
      8 de octubre de 1773: Se hizo cargo del Curato del Partido del Rosario, en
      la provincia de Salta, don Pedro de Toledo y Pimentel. Este sacerdote era
      Vicario del Obispado del Tucumán y era natural de Salta. Fue descendiente
      de conquistadores. Estudió filosofía, teología, cánones y moral. Se
      doctoró en la Universidad de Córdoba.
    </p>
    <p>
      8 de octubre de 1803: Nació en Salta, José Güemes, hermano menor el héroe.
      Se incorporó a las armas casi niño. Fue ayudante de Belgrano. Pueyrredón
      en 1815 lo ascendió a Teniente, luego ingresó a las fuerzas de su hermano.
      En 1820 llegó al grado de Teniente Coronel. Acompañó al general José
      Ignacio de Gorriti, en 1830, en la campaña contra las provincias de
      Catamarca y La Rioja. En 1831 se radicó en Tilcara, Jujuy. En diciembre de
      1831 depuso al gobernador Rudencio Alvarado. Asumió interinamente al
      gobierno de la provincia hasta el 20 de febrero de 1832 en que fue
      derrotado por el general Pablo Latorre, en Cerrillos. Murió en Salta a los
      37 años el 13 de diciembre de 1840.
    </p>
    <p>
      8 de octubre de 1814: Se separó Salta del Tucumán. Las ciudades de Salta,
      Jujuy, Orán y Santa Marís con sus distritos, forman parte de la
      Intendencia de Salta. Las ciudades de Santiago del Estero, Tucumán y
      Catamarca de la Intendencia de Tucumán. De ese modo, Salta se constituyó
      en provincia, por Decreto de Gobierno de Buenos Aires. El Director Supremo
      Gervasio A. de Posadas, creó la provincia de Tucumán con jurisdicción
      sobre los territorios de Tucumán, Santiago del Estero y Catamarca.
    </p>
    <p>
      8 de octubre de 1961: Se creó la Agrupación de Gauchos “Fortín La Silleta”
      en la localidad del mismo nombre.
    </p>
    <p>
      8 de octubre de 1986: Salió del Aeropuerto Salta un vuelo hacia Roma, con
      un contigente de 300 personas, ya que al tener conocimiento de la visita
      Apostólica de su Santidad Juan Pablo II a la Argentina el Poder Ejecutivo
      de la Provincia, lo invitó especialmente a visitar Salta. Todo el pasaje
      asistió a la audiencia pública con Juan Pablo II en la Plaza de San Pedro,
      en el Vaticano, el 15 de octubre de 1986. El Arzobispo de Salta, Monseñor
      Moisés Julio Blanchoud, que presidía la delegación, formuló la invitación
      en representación del Católico Pueblo Salteño. Entre los que viajaron, el
      licenciado Colmenares, los hermanos Oscar y Gerardo Monterrichel, el
      empresario Alberto Garzón y variadas personalidades de la sociedad
      salteña.
    </p>
    <p>
      9 de octubre de 1732: Nació Francisco Gabino Arias: Gobernador de Tucumán
      entre 1775 y 1777. Siguiendo las márgnes del Bermejo fundó las poblaciones
      de San Bernardo y La Cangayé. Organizó numerosas reducciones indígenas,
      procurando someterlas a la civilización hispánica. Murió en la pobreza en
      Salta en 1808.
    </p>
    <p>
      9 de octubre de 1794: Nació en Rosario de Lerma (Salta), José Hermenegildo
      Diez y Torena. Adolescente informante de don José de Moldes y del doctor
      José Ignacio Gorriti, era conocido con el sobrenombre de “Marucho”.
      Combatió en la Batalla de Salta el 20 de febrero de 1813. Fue mediador
      entre los bandos políticos. Alcanzó el grado de capitán.
    </p>
    <p>
      9 de octubre de 1804: Nació en Salta, Dionisio Puch. Colaboró con su
      cuñado el General Güemes, Gobernador de Salta, desde el 19 de octubre de
      1856 hasta el 6 de junio de 1857. Murió en Lisboa en 1857.
    </p>
    <p>
      9 de octubre de 1835: Murió en Seclantás (Salta) Luis Borja Díaz. Nacido
      en San José de Cachi (Salta). Cuando los ejércitos patriotas llegaron a
      Salta rumbo al Perú don Luis Borja Díaz aportó dinero, hombres, haciendas
      y cosechas para el mantenimiento de las tropas. Ingresó al “Regimiento de
      Patriotas”, participó de las batallas de Tucumán y Salta, militó a las
      órdenes del General Gorriti. En 1827 fue ascendido a coronel.
    </p>
    <p>
      9 de octubre de 1854: Nació en Salta, Pedro Nolasco Fierro. En Córdoba
      ingresó al seminario de Nuestra Señora de Loreto y luego a la Universidad
      de Córdoba donde se doctoró en Derecho Canónico, además de los títulos de
      Maestro y Licenciado en Teología. En 1877 se oredenó de Sacerdote. De
      regreso en Salta fue docente en el Seminario Diocesano, donde llegó a ser
      rector. Dondiscípulo del doctor Clodomiro Arce, colaboró con éste para
      lograr la instalación de las religiosas esclavas del Sagrado Corazón de
      Jesús, que dirigen el Colegio de Jesús. Murió en Salta el 12 de setiembre
      de 1904.
    </p>
    <p>
      9 de octubre de 1867: Luego de pasar por San Carlos, Cafayate, Alemania,
      La Viña, Ampascachi, Puerta de Díaz o Coronel Moldes, El Carril, en esta
      jornada el temido Felipe Varela con sus Laguneros, se encontraba en las
      puertas mismas de la ciudad de Salta.
    </p>
    <p>
      9 de octubre de 1915: Falleció en Salta, Antonino Díaz, nacido en 1857. Se
      graduó en 1882 y alcanzó el doctorado en jurisprudencia. Fue Diputado
      Provincial y Gobernador de la provincia desde el 20 de febrero de 1896
      hasta el 20 de febrero de 1898 y Senador por Salta en el Congreso de la
      Nación.
    </p>
    <p>
      10 de octubre de 1777: Nació en Salta, Celedonio Molina y Berdeja. Asistió
      a los heridos en la batalla del 20 de febrero de 1813. Bendijo “la Campana
      de la libertad” que fue fundida con el bronce de los cañones enemigos y
      que ahora pende de la torre del templo de San Francisco en Salta
      (Capital). Murió en Molinos en 1852.
    </p>
    <p>
      10 de octubre de 1867: El caudillo Felipe Varela asaltó la ciudad de Salta
      infructuosamente, siendo defendida por los salteños a las órdenes del
      general Navarro, de Martín Cornejo, Juan M. Leguizamón y Nicanor Flores,
      en Salta.
    </p>
    <p>
      10 de octubre de 1982: Se creó el Grupo Vocación de Orán. Nació con el
      impulso de un puñado de jóvenes con inquietudes artísticas. Editaron hasta
      el presente una decena de libros y una treintena de cartillas y plaquetas
      literarias. Realizaron exposiciones plásticas, recitales de canto y
      música, espectáculos de danzas, representaciones teatrales, certámenes
      artísticos y programas radiales y televisivos. Fecunda labor cultural.
    </p>
    <p>
      10 de octubre de 1986: Inició el V Festival Latinoamericano de Folklore.
      Se realizó hasta el 19 de octubre. Reunió a muchos países latinoamericanos
      y delegaciones de casi todas las provincias argentinas.
    </p>
    <p>
      11 de octubre de 1859: Nació en Salta, Juan Pablo Arias Romero. Se graduó
      de médico en Buenos Aires con la tesís “Fiebre pauperal”. Lucho contra el
      cólera en Metán. Fue rector del Colegio Nacional. Estaba casado con doña
      Francisca Güemes, bisnieta del héroe. Falleció en Salta en 1909.
    </p>
    <p>
      12 de octubre de 1938: Apareció en Metán, el periódico Horizontes, que
      dirigía el doctor Carlos S. Poma. Salió hasta fines de 1939.
    </p>
    <p>
      12 de octubre de 1942: Se creó en Metán, la Sociedad del Hogar y Ayuda
      Social, con el propósito de dar albergue, alimento y abrigo a los niños
      huérfanos o desvalidos. Estaba en Villa San José y fue conocido con el
      nombre de “El Asilo”. Su fundadora fue la señora Aurora Bustamante Lizondo
      de Poma.
    </p>
    <p>
      12 de octubre de 1963: Asumió como gobernador de Salta el doctor Ricardo
      Joaquín Durand y vicegobernador el doctor Eduardo Paz Chain.
    </p>
    <p>
      13 de octubre de 1878: El Obispo doctor Miguel Moisés Aráoz, consagró la
      Catedral de Salta, faltándole las torres y el actual frente.
    </p>
    <p>
      13 de octubre de 1914: Falleció en Tucumán, Francisco Mendioroz. Nació en
      Salta. Médico, combatió la epidemia del cólera desatada en 1886/87.
      Integró el Consejo Provincial de Higiene. Presidente del Consejo
      Deliberante.
    </p>
    <p>
      14 de octubre de 1659: Alonso de Mercado y Villacorta, gobernador de
      Tucumán, derrotó a los indígenas sublevados en Hualfin, provincia de
      Salta.
    </p>
    <p>
      14 de octubre de 1850: Nació en Salta, Carlos Costas. Obtuvo el título de
      médico cirujano en 1876. Murió el 10 de junio de 1928.
    </p>
    <p>
      14 de octubre de 1862: Nació en Salta, Gregorio Romero. Recibió la
      ordenación sacerdotal en 1886. Profesor de teología. Doctor en Derecho
      Canónigo. Condecorado con la cruz “Pro Ecclessia et Pontifex”. “Caballero
      de la Orden San Basilio”. Vicario Capitular a cargo de la sede vacante
      ante el deceso de su titular Monseñor Linares. Fundó el diaro católico
      “Tribuna Popular”. Falleció el 17 de setiembre de 1919. Fue sepultado en
      el recinto de la Catedral y trasladado al panteón de las Glorias del
      Norte.
    </p>
    <p>
      15 de octubre de 1841: Fue ejecutado en Salta, el comandante Gregorio
      Sandoval, por orden del gobernador de la provincia, por haber sido el
      entregador del Dr. Marco M. de Avellaneda, quien fue ejecutado en Metán el
      3 de octubre de 1841.
    </p>
    <p>
      15 de octubre de 1966: Se colocó la piedra fundamental del edificio de la
      Universidad Católica de Salta, en su extenso terreno de Castañares. Se
      eligió ese día por ser el de Santa Teresa, patrona dela Universidad. La
      bendición estuvo a cargo de monseñor Pérez. Asistió Eduardo Patrón Costas,
      hijo del principal benefactor don Robustiano Patrón Costas, fallecido el
      año anterior.
    </p>
    <p>
      15 de octubre de 1975: El ministro de gobierno de la provincia de Salta,
      jorge ArandaHuerta, se hizo cargo del gobierno salteño hasta el 22 de
      noviembre, cuando asumió el Interventor, escribano Ferdinando Pedrini.
    </p>
    <p>
      15 de octubre de 1977: Visitó el Instituto de Patología Regional de Salta,
      el premio nobel, doctor Federico Leloir.
    </p>
    <p>
      16 de octubre de 1785: Nació en Salta, Juan Galo de Leguizamón. Actuó en
      la batalla del 24 de setiembre de 1812, al cabo de la cual fue ascendido a
      teniente. Fue herido de bala en la batalla del 20 de febrero de 1813. En
      misión encomendada por el General Güemes -en la Quebrada de Humahuaca- se
      enfrentó con el General Valdés al que derrotó y arrebató una bandera que
      permaneció en poder de los infernales, hasta que Güemes se la entregó como
      premio a su constante colaboración. Después de la muerte del héroe, el
      General Gorriti le entregó los despachos de Coronel en 1822. Murió el 29
      de noviembre de 1868 en Tucumán.
    </p>
    <p>
      16 de octubre de 1803: Nació en Salta, don Mariano Fortunato Boedo. A los
      10 años ingresaba como cadete militar. Integró las milicias que comandaba
      el Gral. Güemes. Fue promovido al grado de capitán. Recibió los despachos
      de coronel. Fue fusilado por los unitarios en Campo Santo el 31 de julio
      de 1841.
    </p>
    <p>
      16 de octubre de 1956: Se fundó en la ciudad de Salta, la Clínica Cruz
      Azul.
    </p>
    <p>
      17 de octubre de 1778:&nbsp;Nació en Salta, José Andrés Pacheco de Melo,
      condiscípulo de Güemes. Doctor en derecho canónigo. Apoyó a Belgrano,
      colaboró con Güemes. Diputado al Congreso de Tucumán en representación de
      la Provincia de Salta.
    </p>
    <p>
      17 de octubre de 1837: Nació en Rosario de Lerma (Salta), Justo Castro.
      Desde muy joven se dedicó al comercio de mulas con Bolivia. Vinculado con
      Domingo F. Sarmiento y con su apoyo introdujo a San Juan la primera
      máquina a vapor y organizó empresas comerciales e industriales. Siendo
      Sarmiento presidente, Castro hizo traer desde Burdeos (Francia), un
      cargamento de cepas con las que realizó las primeras plantaciones de viñas
      en San Juan. Instaló modernas maquinarias y nació la primer gran industria
      vitivinícola sanjuanina. No descuidó sus negocios de mulas, su planteles
      llegaron a ser los más cotizados. Obtuvo por ellos en 1872, un premio en
      la exposición ganadera realizada en Córdoba. Fundó en Buenos Aires una
      empresa comercial con Francisco Uriburu y don Luis Castels. Obtuvo el
      primer premio en vinos en la Exposición Nacional de 1890, celebrada en
      Buenos Aires. El renunciar Domingo Morón, pasó constitucionalmente a
      ocupar el cargo de Gobernador de San Juan hasta 1897. Falleció en San Juan
      el 13 de octubre de 1900.
    </p>
    <p>
      17 de octubre de 1838: Producida la muerte de monseñor José Agustín
      Molina, el Cabildo Eclesiástico de Salta designó vicario Capitular a don
      José Gabriel de Figueroa, medida que fue criticada por los tucumanos y
      calificada de nula por el canónigo jujeño Bárcena. Pero el Vicario
      Figueroa pudo gobernar la diócesis hasta su muerte ocurrida el 29 de
      agosto de 1840.
    </p>
    <p>
      17 de octubre de 1907: Nació en Salta, Julio Barbarán Alvarado. Director
      de “Nueva Epoca”. Ingresó a la redacción de “El Intransigente”. Diputado
      provincial en 1950. Fue Ministro de Gobierno hasta 1961, en que fue
      decretada la intervención federal a la provincia por el poder Ejecutivo
      Nacional. Propugnó el primer certamen. “Premio a la producción literaria,
      científica y artística”, realizado a través de la Dirección de Turismo y
      Cultura. Cultivó el género narrativo escribiendo cuentos y fábulas
      publicados en diarios y revistas. Falleció en Salta el 25 de noviembre de
      1967.
    </p>
    <p>
      17 de octubre de 1938: Nació en Salta, Eduardo González Galli. Médico
      Cirujano que se desempeña en el Hospital del Milagro. Profesor del Colegio
      Nacional y del Colegio General San Martín. Creador del Movimiento
      Ecológico Sanmartiniano “Amigos del Río Arenales”.&nbsp;
      <br />
      17 de octubre de 1944: Nació en Campo Santo, provincia de Salta, Ricardo
      Nallar, Poeta y escritor. Publicó: Recuerdos de mi pueblo (1988); Ecos de
      mi tierra (1991); Sangre Inmortal (1994). Participó en encuentros y logró
      reconocimientos y premios.
    </p>
    <p>
      18 de octubre de 1844: A las 22:30 horas ocurrió un terrible terremoto en
      Jujuy, Salta, Tucumán y Santiago del Estero, que dejó en ruinas a esas
      ciudades.
    </p>
    <p>
      18 de octubre de 1897: Nació en Salta Francisco Javier Arias. Egresó con
      el titulo de médico en 1924 de la Facultad de Medicina de Buenos Aires.
      Miembro de la Sociedad Argentina de Cirugía, del Colegio Internacional de
      Cirujanos, de la Sociedad Endocrinología de los Angeles (USA), dela
      Sociedad Patológica del Norte. Miembro fundador de la Sociedad Argentina
      de Cirugía Toráxica, Socio fundador del Círculo Médico de Salta. Murió el
      13 de julio de 1968.
    </p>
    <p>
      18 de octubre de 1944: Nació en Campo Santo, provincia de Salta, Ricardo
      Nallar. Poeta y escritor. Publicó: “Recuerdos de mi Pueblo” (1988); “Ecos
      de mi Tierra” (1991); “Sangre Inmortal” (1994). Participó en encuentros y
      logró reconocimientos y premios.
    </p>
    <p>
      19 de octubre de 1831: El general Felipe Ibarra, federal, derrotó al
      coronel Videla Castillo y Pedernera, unitario, en Río Hondo, provincia de
      Santiago del Estero.
    </p>
    <p>
      19 de octubre de 1900: Se instaló la Sociedad Rural Salteña, encargada de
      promover el desarrollo integral del agro por medio de una mejor
      capacitación técnica en la materia.
    </p>
    <p>
      19 de octubre de 1916: Nació el doctor José René Albeza. Fue presidente
      del Círculo Médico de Salta en el período: 1960-1961. Murió el 25 de mayo
      de 1973.
    </p>
    <p>
      19 de octubre de 1938: Se aprobó por Ley Nº 15.169, el proyecto para
      ejecutar la obra del Colegio Nacional de Salta, que su actual edificio.
    </p>
    <p>
      19 de octubre de 1995: Se inauguró la XII FERINOA, en la ciudad de Salta,
      organizada por la Cámara de Comercio Exterior.
    </p>
    <p>
      20 de octubre de 1891: Falleció en la Capital Federal, Martín Torino.
      Nació en Salta en 1835. Se radicó joven en Jujuy. Fue gobernador de Jujuy
      desde el 25 de abril de 1878 hasta el 12 de mayo de 1879. Derrocado por el
      doctor Sánchez de Bustamante, regresó a Salta. En Salta fue jefe de
      Policía. Luego fijó su residencia en Capital Federal donde fue nombrado
      Administrador del Hospital Militar.
    </p>
    <p>
      20 de octubre de 1926: Murió en Salta, Adrian F. Cornejo, quien nació en
      Salta en 1859. En 1883 se graduó en abogacía. Se dedicó a su profesión,
      ocupando cargos en la justicia. Estudió acerca del pasado salteño. Entre
      sus obras: “Boceto biográfico del doctor Facundo de Zuviría” y
      “Contribución a la historia de Salta y sus próceres” (inédito).
    </p>
    <p>
      21 de octubre de 1841: Fue ejecutado en Salta, el comandante Gregorio
      Sandoval por orden del gobernador de la provincia, al haber sido el
      entregador del doctor Marco M. de Avellaneda y del coronel José María
      Vilela, ambos unitarios y que fueron ejecutados en Metán, el 3 de octubre
      de 1841.
    </p>
    <p>
      21 de octubre de 1864: Nació en San Ramón de la Nueva Orán, Daniel
      Bouchard. Ingresó a la carrera militar a los 15 años. alcanzó el grado de
      Teniente Coronel. Murió en Buenos Aires el 9 de abril de 1900.
    </p>
    <p>
      21 de octubre de 1923: Tomó posesión como obispo de Salta, monseñor Julio
      Campero y Aráoz, quien había nacido en Jujuy en 1873. Estudió en el
      Seminario de Salta, y ordenado sacerdote se incorporó a la diócesis de
      Salta. Lo designó el Papa Pío XI. Fue el séptimo obispo de Salta.
    </p>
    <p>
      21 de octubre de 1995: El boxeador salteño Víctor Hugo Paz, enfrentó al
      coreano Young Soo Choi, por el título mundial de los livianos, versión
      Asociación Mundial de Boxeo. La pelea se realizó en el Estadio “Delmi”
      Ciudad de Salta y el triunfo le correspondió al coreano.
    </p>
    <p>
      22 de octubre de 1921: Nació en Bustinza, Córdoba, Argentina, José Antonio
      Lastra González. Fue designado Vice cónsul Honorario de España en Salta el
      26 de febrero de 1972. El 24 de junio de 1983 le otorgó el Rey de España
      la Cruz de Caballero de la orden de Isabel la Católica. El diploma lo
      firmó S.M. Juan Carlos I, Rey de España. Colabora con múltiples entidades
      de Salta.
    </p>
    <p>
      22 de octubre de 1927: Nació en Salta don Roberto Romero, quien fue el
      director por muchos años de diario El Tribuno. Fue gobernador de Salta y
      diputado nacional. Padre del actual gobernador, Dr. Juan Carlos Romero.
    </p>
    <p>
      22 de octubre de 1957: Nació en Salta, María Cristina Felipe Gómez Solá,
      quien se graduó de profesora y luego de licenciada en Psicología, en la
      Universidad Católica de Salta. Se desempeñó en el Hospital de Niños; en la
      Dirección General de Administración de Personal; en el Servicio
      Penitenciario; Participó en la Reforma del Estado; llegó a ser Presidente
      del ISEIS; prestó servicios profesionales en la Policía de la provincia y
      en la justicia; se desempeña como directora del CENS Nº 7096, ex Dinea.
      Además dictó cursos de su especialidad y fue docente regular en distintos
      establecimientos educativos.
    </p>
    <p>
      23 de octubre de 1938: Nació en Salta, Pedro Servando Fleitas, quien es
      autor de gran cantidad de canciones folklóricas. Compuso con horacio
      Aguirre, Daniel Toro entre otros. Grabaron sus temas Los Cantores del
      Alba, Daniel Toro, Los Hermanos Gutiérrez y otros.
    </p>
    <p>
      24 de octubre de 1874: Nació en Salta, Julio Cornejo. Se graduó de abogado
      en 1898, se doctoró en jurisprudencia. Regresó al terruño colocándose al
      frente del Ingenio San Isidro, en Campo Santo. el 1º de mayo de 1888
      asumió como gobernador. Murió en mayo de 1935.
    </p>
    <p>
      24 de octubre de 1893: Nació en Salta, Rafael Patricio Sosa. Se recibió de
      ingeniero civil en 1917 en Buenos Aires. De regreso a Salta se dedicó a la
      docencia, a la función pública y al estudio. Varias veces legislador
      provincial, ministro de hacienda, ministro de gobierno, convencional
      constituyente, dos veces presidente del Consejo General de Educación,
      interventor de la Municipalidad de Salta. Profesor del Colegio Nacional.
      Publicó algunos trabajos sobre temas históricos (1973 -editado por la
      Dirección de Cultura). Fue fundador y primer presidente del Instituto
      Belgraniano de Salta. Miembro fundador del Instituto San Felipe y Santiago
      de Estudios Históricos de Salta. Falleció en Salta, el 8 de marzo de 1979.
    </p>
    <p>
      25 de octubre de 1796: Nació en Salta, Evaristo de Uriburu. Se incorporó a
      las fuerzas que dirigía Belgrano en 1812. Participó en las batallas del
      Río de las Piedras, Tucumán, Salta, Vilcapugio, Ayohuma, Sipe-Sipe.
      Invitado por el coronel Zegada marchó a La Rioja en 1817 y se sumó a las
      tropas del Libertador San Martín. Estuvo en Chacabuco. En 1825 regresó a
      Salta. En tres ocasiones ejerció el mando gubernativo de Salta, como
      gobernador sustituto. Falleció en Buenos Aires el 28 de julio de 1865.
    </p>
    <p>
      25 de octubre de 1914: Murió en Buenos Aires, el doctor José Evaristo
      Uriburu, nacido en Salta. Fue presidente de los argentinos desde el 23 de
      enero de 1895 hasta el 12 de octubre de 1898.
    </p>
    <p>
      25 de octubre de 1935: Nació en Salta, Alberto Domingo Abudi Masri.
      Odontólogo que fue Presidente de la Asociación Odontológica Salteña y
      presidente de la Comisión de Pesca del Club Los Mayuatos. Activo dirigente
      y protector de entidades culturales y de artistas salteños.
    </p>
    <p>
      26 de octubre de 1768: Nació en Salta, José de Gurruchaga. Se educó en el
      Colegio de Nobles de Madrid, España. Fue presidente de la Logia Lautaro.
      Regresó en enero de 1809 a Buenos Aires, junto con otros americanistas.
      Colaborá con el Ejército del Norte en víveres, ropas, dienro. Colaborador
      especial de Güemes. Se casó con Martina Silva y Fernández de Córdoba, que
      organizó el Escuadrón de Ponchos Azules. Le dieron el título de Capitana
      del Ejército del Norte. Murió en Salta el 19 de marzo de 1834. Sus restos
      descansan en la Iglesia de San Francisco.
    </p>
    <p>
      26 de octubre de 1855:&nbsp;El Cabildo Eclesiástico eligió Vicario
      Capitular al sacerdote tucumano José Eusebio Colombres, teniendo en cuenta
      la petición de Justo José de Urquiza, que presentaba a la Santa Sede al
      canónigo Colombres para Obispo Diócesano de Salta.
    </p>
    <p>
      26 de octubre de 1947: Nació en El Tala, departamento de La Candelaria,
      provincia de Salta, Rubén Pérez. Estudió en su pueblo, luego en el
      Seminario Conciliar. Trabajo en Radio Nacional como empleado técnico y
      también realizó conducción de programas. A los 14 años compuso Mi
      Taleñita, tema grabado por varios artistas argentinos como Las Voces de
      Orán. Daniel Toro, con quien compuso El Cielo de la Baguala. Realizó giras
      artísticas por Europa. Publicó juntamente con la poetisa española Chus
      Feteira el libro: Poemas Transoceánicos, que editó el Instituto Cultural
      Andino y prologó el poeta Eduardo Ceballos.
    </p>
    <p>
      27 de octubre de 1683: En el Chaco, en el Valle de Zenta, en la capilla de
      Santa María, fue consumado el martirio del prebístero don Pedro Ortiz de
      Zárate, vicario de Jujuy, del sacerdote Jesuita Antonio Solinas y sus
      acompañantes. Los restos del vicario don Pedro Ortiz de Zárate fueron
      llevados a Jujuy y los del Padre Solinas a Salta. Los habían destrozados y
      decapitados. Por eso a don Pedro Ortiz de Zárate se lo llama, el Mártir
      del Valle de Zenta.
    </p>
    <p>
      27 de octubre de 1829: Nació en Salta, Moisés Oliva. Médico. Diputado
      Provincial en Salta. Diputado Nacional por Salta. Gobernador de Salta 1879
      – 1881. Murió en 1890.
    </p>
    <p>
      28 de octubre de 1819: El capitán Alejandro Burela, del ejército de
      Güemes, derrotó a los realistas, en San Antonio de los Cobres, provincia
      de Salta.
    </p>
    <p>
      29 de octubre de 1880: Nació en Salta Nestor Patrón Costas. Inició las
      plantaciones tabacaleras en Rosario de Lerma. Diputado Provincial.
      Diputado Nacional. Falleció el 10 de marzo de 1962 en Buenos Aires.
    </p>
    <p>
      29 de octubre de 1884: El Consejo Municipal de Metán provincia de Salta,
      llamó a licitación para hacer un camino de San José a la Punta del Agua,
      hasta dar con el carril nacional de Salta a Tucumán.
    </p>
    <p>
      29 de octubre de 1910: La provincia de Salta, autorizó la venta de las
      Termas de Rosario de la Frontera.
    </p>
    <p>
      29 de octubre de 1920: Se creó la Biblioteca Provincial Victorino de la
      Plaza, con los volúmenes que éste donara, más los que se agregaron de la
      vieja biblioteca. Su primer Director fue el escribano Casiano Hoyos y su
      secretario Bernado González Arrili. Era gobernador de Salta, el Dr.
      Joaquín Castellanos. Entre sus directores: Juan Carlos Dávalos, Manuel J.
      Castilla, Antonio Nella Castro, José Fernández Molina.
    </p>
    <p>
      30 de octubre de 1889: Se creó la Biblioteca Pública en La Rioja, por
      iniciativa de su gobernador Joaquín V. González.
    </p>
    <p>
      30 de octubre de 1934: Por ley se ordenó la construcción del Edificio de
      Correos en la ciudad de Salta.
    </p>
    <p>
      30 de octubre de 1983: En elecciones generales a nivel nacional triunfó el
      doctor Ricardo Alfonsín y en la provincia de Salta, el señor Roberto
      Romero y como vicegobernador el Geólogo Jaime Hernán Figueroa. Autoridades
      que asumieron el 10 de diciembre de 1983.
    </p>
    <p>
      30 de octubre de 1990: Falleció en Bogotá, el doctor Bernardino M.
      Galliano, güemesiano, quien era vocal del Consejo Directivo del Instituto
      Güemesiano de Salta.
    </p>
    <p>
      30 de octubre de 1994: El doctor Juan Carlos Romero, ganó las elecciones
      internas del justicialismo salteño.
    </p>
    <p>
      31 de octubre de 1811: Se creó el cuerpo Dragones Patricios de Caballería
      de Salta. Funcionó hasta el 23 de agosto de 1817.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
